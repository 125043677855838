@import "../../assets/scss/breakpoints";
@import "../../assets/scss/variables";

.dashed {
  position: relative;
  &::after {
    content: '';
    border: 2px dashed $body-color;
    height: 0;
    width: 50%;
    position: absolute;
    left: 75%;
    bottom: 30%;
  }
}

.dashed.error {
  &::after {
    border-color: $danger;
  }
}

.dashed.on-hold {
  &::after {
    border-color: $body-color;
  }
}

.dashed.success {
  &::after {
    border-color: $blue;
  }
}

@include media-breakpoint-down(md) {
  .stepper {
    &__circle {
      position: absolute;
      left: 0;
    }
  }
  .dashed {
    position: relative;
    &::after {
      content: '';
      border: 2px dashed red;
      height: 50%;
      width: 0;
      position: absolute;
      left: 12%;
      top: 85%;
    }
  }
}
