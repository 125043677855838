.Addresses {
  .feature {
    max-width: 750px;

    div > span:first-child {
      display: inline;
      width: max-content;
    }
  }
}
