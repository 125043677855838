$blue: #4FADEA;
$green: #77C33A;
$red: #DF3021;
$yellow: #F7CF87;
$dark-blue: #1C2648;
$body-color: #5C5C5C;


$primary: $blue;
$success: $green;
$warning: $yellow;
$danger: $red;


$theme-colors: (
        "primary": $primary,
        "success": $success,
        "warning": $warning,
        "danger": $danger,
        "dark-blue": $dark-blue
);

